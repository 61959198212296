import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import strings from "../../../localization/strings";
import Form from "../../core/Form/Form/Form";
import styles from "./EmbeddedCodes.module.scss";
import { getIframeLink } from "../../../utils.ts/Urls";
import { toastSuccess } from "../../../utils.ts/Toaster";
import { TagsContext } from "../../../contexts/TagsContext";
import FormMultiDataListInput from "../../core/Form/FormMultiDataListInput/FormMultiDataListInput";
import { FilterSpecs, GetSearchParamsFromFilters, NamedOptionsFilter } from "../../../hooks/UseSearchParam";
import FormCheckbox from "../../core/Form/FormCheckbox/FormCheckbox";
import { useOrganiserOptions } from "../../../hooks/TagHooks";
import FormDataListInput from "../../core/Form/FormDataList/FormDataListInput";
import cx from "classnames";
enum EmbedType {
    ORGANISATION_LIST = "organisationList",
    HAPPENING_LIST = "happeningList",
    ORGANISATION_MAP = "organisationMap",
    HAPPENING_MAP = "happeningMap",
}

export default function EmbeddedCodes() {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();
    const selectedType: EmbedType = watch("type");
    const watchedGroups = watch("groups");
    const watchedShowFilters = watch("showFilters");

    const tags = useContext(TagsContext).allTags;
    const organiserOptions = useOrganiserOptions();
    const organiserInputOptions = useMemo(
        () => organiserOptions.map((group) => ({ label: group.detailedName, value: group.id })),
        [organiserOptions],
    );
    const typeInputOptions = [
        { label: strings.happenings, value: EmbedType.HAPPENING_LIST },
        { label: strings.showHappeningMap, value: EmbedType.HAPPENING_MAP },
        { label: strings.organisation, value: EmbedType.ORGANISATION_LIST },
        { label: strings.showOrganisationMap, value: EmbedType.ORGANISATION_MAP },
    ];
    const filterSpecs: FilterSpecs = useMemo(
        () => ({
            groups: new NamedOptionsFilter(tags.happeningGroups.concat(tags.partners)),
        }),
        [tags],
    );
    const isGroupFieldEnabled = useMemo(() => {
        return [EmbedType.HAPPENING_LIST, EmbedType.HAPPENING_MAP].includes(selectedType);
    }, [selectedType]);

    const generateAndCopyEmbeddedCode = useCallback(
        async (filters: { groups?: string[]; showFilters: boolean; type: EmbedType }) => {
            const preparedFilters = {
                groups: filters.groups?.map((g) => ({ name: g })) ?? [],
            };

            const param = filters.showFilters ? new URLSearchParams({ filters: "1" }) : new URLSearchParams();
            switch (filters.type) {
                case EmbedType.HAPPENING_MAP:
                    param.set("map", "1");
                    break;
                case EmbedType.ORGANISATION_LIST:
                    param.set("organisation", "1");
                    break;
                case EmbedType.ORGANISATION_MAP:
                    param.set("organisation", "1");
                    param.set("map", "1");
                    break;
            }
            const filterString = GetSearchParamsFromFilters(filterSpecs, preparedFilters, param).toString();

            var iframeHeight = filterString.includes("map=1") ? "100%" : "0";
            // @NOTE(Lejun) use js to dynamically handle iframe height
            var code = `
<script>
    if (window.addEventListener) {
        window.addEventListener("message", sbgIframeMessageHandler);
    } else {
        window.attachEvent("onmessage", sbgIframeMessageHandler);
    }

    function sbgIframeMessageHandler(event) {
        const sbgCalendarIframe = document.querySelector("#sbg-iframe-calendar-widget")
        const isSBGComm = event.source === sbgCalendarIframe.contentWindow;
        if (!sbgCalendarIframe || !isSBGComm) {
            return;
        }
        sbgCalendarIframe.height = event.data.height;
        if (event.data.isInit) {
            sbgCalendarIframe.scrollIntoView({behavior: "smooth"});
        }
    }
</script>
<iframe id="sbg-iframe-calendar-widget" src="${getIframeLink(
                filterString,
            )}" frameBorder="0" width="100%" height="${iframeHeight}" allow="clipboard-write"></iframe>
            `;
            await navigator.clipboard.writeText(code);
            toastSuccess(strings.confirmCopyEmbedCode);
        },
        [filterSpecs],
    );

    useEffect(() => {
        const updateIframe = () => {
            const preparedFilters = {
                groups: watchedGroups?.map((g: string) => ({ name: g })) || [],
            };

            const param = watchedShowFilters ? new URLSearchParams({ filters: "1" }) : new URLSearchParams();
            switch (selectedType) {
                case EmbedType.HAPPENING_MAP:
                    param.set("map", "1");
                    break;
                case EmbedType.ORGANISATION_LIST:
                    param.set("organisation", "1");
                    break;
                case EmbedType.ORGANISATION_MAP:
                    param.set("organisation", "1");
                    param.set("map", "1");
                    break;
            }
            const newFilterString = GetSearchParamsFromFilters(filterSpecs, preparedFilters, param).toString();

            const iframe = document.getElementById("sbg-iframe-calendar-widget") as HTMLIFrameElement;
            if (iframe) {
                iframe.src = getIframeLink(newFilterString);
                iframe.height = newFilterString.includes("map=1") ? "100%" : "0";
            }
        };

        updateIframe();
    }, [selectedType, watchedGroups, watchedShowFilters, filterSpecs]);

    return (
        <div className={styles.embeddedCodeFormContainer}>
            <div className={styles.section}>
                <div className={styles.halfSection}>
                    <Form
                        submitText={strings.copyEmbedCode}
                        onSubmit={generateAndCopyEmbeddedCode}
                        handleSubmit={handleSubmit}
                        disabled={!selectedType}
                    >
                        <div className={styles.title}>{strings.createEmbedCode}</div>
                        <FormDataListInput
                            name={"type"}
                            options={typeInputOptions}
                            label={strings.chooseType}
                            placeholder={strings.makeYourChoice}
                            control={control}
                            errors={errors.happeningGroups}
                            required
                            list={"typeOptions"}
                            containerClassName={cx(styles.typeDropdown, !isGroupFieldEnabled && styles.noGroup)}
                        />
                        {isGroupFieldEnabled && (
                            <FormMultiDataListInput
                                name="groups"
                                options={organiserInputOptions}
                                control={control}
                                label={strings.happeningGroups}
                                errors={errors.happeningGroups}
                                list="happeningGroups"
                            />
                        )}
                        {selectedType && (
                            <FormCheckbox name="showFilters" control={control} label={strings.showFilters} />
                        )}
                    </Form>
                </div>
                {(selectedType === EmbedType.HAPPENING_MAP || selectedType === EmbedType.ORGANISATION_MAP) && (
                    <div className={`${styles.halfSection} ${styles.map}`}>
                        <div className={styles.title}>{strings.example}</div>
                        <iframe
                            id="sbg-iframe-calendar-widget"
                            frameBorder="0"
                            width="100%"
                            height="100%"
                            allow="clipboard-write"
                            title={`${selectedType}`}
                        ></iframe>
                    </div>
                )}
            </div>
        </div>
    );
}
