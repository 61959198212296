import moment from "moment";
import { Profile } from "../contexts/ProfileContext";
import {
    HappeningListItemViewModel,
    HappeningState,
    IndicatorListOutput,
    IndicatorStatus,
    MeasurementListOutput,
    MeasurementOutput,
    Role,
} from "../openapi/backend";
import { BareHappening } from "../utils.ts/Happening";
import { isEditibleMeasurementState } from "../utils.ts/MeasurementUtils";
import { userIsLinked, userIsLinkedList } from "./HappeningMethods";
import {
    isApplicationAdmin,
    isApprover,
    isChiefEditor,
    isChiefIntermediary,
    isEditor,
    isIntermediary,
    isMarketing,
    isPartner,
    isPartnerIntermediary,
    isUserAdmin,
    RoleCheck,
} from "./UserMethods";

export type PermissionCheck = (user?: Profile) => boolean;

function allows(roles: RoleCheck[], user?: Profile): boolean {
    return roles.some((check) => check(user));
}

function createCheck(...roles: RoleCheck[]): PermissionCheck {
    return (user) => allows(roles, user);
}

export const canViewHappenings = createCheck(isChiefEditor, isEditor, isPartner, isApprover);
export const canViewPartnerSuggestionHappeningsTab = createCheck(isChiefEditor, isApprover);
export const canViewEmbeddedCodesTab = createCheck(isApplicationAdmin, isMarketing);
export const canViewMeasurements = createCheck(isChiefEditor, isEditor, isPartner);
export const canViewIndicators = createCheck(isChiefEditor, isEditor, isPartner);
export const canViewMonitoring: PermissionCheck = (user) => canViewMeasurements(user) || canViewIndicators(user);
export const canViewMonitoringStatistics = createCheck(isChiefEditor);
export const canCreateIndicator = createCheck(isChiefEditor);
export const canEditIndicator = createCheck(isChiefEditor);
export const canCreateMeasurement = createCheck(isChiefEditor, isEditor, isPartner);
export const canEditMeasurement = createCheck(isChiefEditor, isEditor, isPartner);
export const canCreateHappenings = createCheck(isChiefEditor, isEditor);
export const canSuggestHappenings = createCheck(isPartner);
export const canViewUsers = createCheck(isUserAdmin);
export const canViewAppSettings = createCheck(isApplicationAdmin, isMarketing);
export const canViewAppSettingsDefaultTabs = createCheck(isApplicationAdmin);
export const canViewBO = createCheck(
    isChiefEditor,
    isEditor,
    isUserAdmin,
    isApplicationAdmin,
    isPartner,
    isMarketing,
    isApprover,
    isIntermediary,
    isChiefIntermediary,
    isPartnerIntermediary,
);
export const canSendGeneralNotifications = createCheck(isApplicationAdmin, isMarketing);
export const canEditUsers = createCheck(isUserAdmin);
export const canDeleteUsers = createCheck(isUserAdmin);
export const canEditUserProfiles = createCheck(isUserAdmin, isChiefEditor, isEditor, isPartner);
export const canOnlySuggestHappenings: PermissionCheck = (user) =>
    canSuggestHappenings(user) && !canCreateHappenings(user);
export const canSuggestOrCreateHappenings: PermissionCheck = (user) =>
    canSuggestHappenings(user) || canCreateHappenings(user);
export const canCreateOrganisationInfo = createCheck(isApplicationAdmin);
export const canViewApplications = createCheck(isChiefIntermediary, isIntermediary, isPartnerIntermediary);
export const canEditApplications = createCheck(isChiefIntermediary, isIntermediary, isPartnerIntermediary);
export const canCreateApplications = createCheck(isChiefIntermediary, isIntermediary, isPartnerIntermediary);
export const canAssignApplications: PermissionCheck = (user) =>
    isChiefIntermediary(user) || isIntermediary(user) || isPartnerIntermediary(user);
export const canUpsertClusters = createCheck(isChiefEditor, isEditor, isPartner);
export const canManageMediaLibrary = createCheck(isApplicationAdmin, isMarketing);

export function canEditUserProfile(user?: Profile, profile?: Profile): boolean {
    if (!profile || !user) {
        return false;
    }

    if (isUserAdmin(user)) {
        return true;
    }

    if (isChiefEditor(user) || isEditor(user)) {
        return user.id === profile.id || profile.roles?.includes(Role.Participant);
    }

    if (isPartner(user)) {
        return user.id === profile.id;
    }

    return false;
}

export function canSendHappeningNotifications(happening: BareHappening, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if (isEditor(user) || isPartner(user)) {
        return userIsLinked(happening, user);
    }

    return false;
}

export function canViewProtectedHappeningFields(happening: BareHappening, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if (isEditor(user) || isPartner(user) || isApprover(user)) {
        return userIsLinked(happening, user);
    }

    return false;
}

export function canManageHappeningParticipants(happening: BareHappening, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if ((isEditor(user) || isPartner(user)) && happening.state === HappeningState.Open) {
        return userIsLinked(happening, user);
    }

    return false;
}

export function canEditHappening(happening: BareHappening, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if (isApprover(user)) {
        return (
            happening.state === HappeningState.PartnerSuggestion ||
            happening.state === HappeningState.Rejected ||
            ((happening.state === HappeningState.Open || happening.state === HappeningState.Concept) &&
                userIsLinked(happening, user))
        );
    }

    if (isEditor(user)) {
        return (
            userIsLinked(happening, user) &&
            (happening.state === HappeningState.Open ||
                happening.state === HappeningState.Concept ||
                happening.state === HappeningState.PartnerSuggestion ||
                happening.state === HappeningState.Rejected)
        );
    }

    if (isPartner(user)) {
        const editableStates = [HappeningState.Concept, HappeningState.Rejected];
        const editableStatesForOwner = editableStates.concat([
            HappeningState.Open,
            HappeningState.PartnerSuggestion,
            HappeningState.PlannedForPublication,
        ]);
        return (
            (userIsLinked(happening, user) && editableStates.includes(happening.state)) ||
            (happening.createdBy?.id === user!.id && editableStatesForOwner.includes(happening.state))
        );
    }

    return false;
}

export function canEditHappeningOverview(happening: HappeningListItemViewModel, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if (isEditor(user)) {
        return (
            userIsLinkedList(happening, user) &&
            (happening.state === HappeningState.Open ||
                happening.state === HappeningState.Concept ||
                happening.state === HappeningState.PartnerSuggestion ||
                happening.state === HappeningState.Rejected)
        );
    }
    return false;
}

export function canForceEditHappening(user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    return false;
}

export function canDeleteHappening(
    happening: BareHappening,
    user?: Profile,
    hasRegisteredParticipants: boolean = false,
): boolean {
    if (isChiefEditor(user) && !hasRegisteredParticipants) {
        return true;
    }

    if (isApprover(user)) {
        return happening.state === HappeningState.PartnerSuggestion;
    }

    if (isEditor(user)) {
        return happening.state === HappeningState.Concept && userIsLinked(happening, user);
    }

    if (isPartner(user)) {
        // Checking displayState since Partners cannot delete planned happenings
        return happening.displayState === HappeningState.Concept && userIsLinked(happening, user);
    }

    return false;
}
export function canDeleteHappeningOverview(
    happening: HappeningListItemViewModel,
    user?: Profile,
    hasRegisteredParticipants: boolean = false,
): boolean {
    if (isChiefEditor(user) && !hasRegisteredParticipants) {
        return true;
    }

    if (isEditor(user)) {
        return happening.state === HappeningState.Concept && userIsLinkedList(happening, user);
    }

    return false;
}

export function canCancelAHappening(happening: BareHappening, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if (isEditor(user)) {
        return (
            userIsLinked(happening, user) &&
            (happening.state === HappeningState.Open ||
                happening.state === HappeningState.Concept ||
                happening.state === HappeningState.PartnerSuggestion)
        );
    }

    if (isPartner(user)) {
        return (
            (userIsLinked(happening, user) || happening.createdBy?.id === user!.id) &&
            happening.state === HappeningState.Open
        );
    }

    return false;
}

export function canCancelAHappeningOverview(happening: HappeningListItemViewModel, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if (isEditor(user)) {
        return (
            userIsLinkedList(happening, user) &&
            (happening.state === HappeningState.Open ||
                happening.state === HappeningState.Concept ||
                happening.state === HappeningState.PartnerSuggestion)
        );
    }

    return false;
}

export function hasUpcomingSession(happening: BareHappening): boolean {
    if (!happening.dateTimes || happening.dateTimes.length === 0) {
        return false;
    }

    const lastSession = happening.dateTimes[happening.dateTimes.length - 1];
    return moment().isBefore(lastSession.sessionEnd);
}

export function canArchiveHappening(happening: BareHappening, user?: Profile): boolean {
    if (isChiefEditor(user) && !hasUpcomingSession(happening)) {
        return happening.state === HappeningState.Open;
    }

    return false;
}

export function canRejectHappening(happening: BareHappening, user?: Profile): boolean {
    return (isChiefEditor(user) || isApprover(user)) && happening.state === HappeningState.PartnerSuggestion;
}

export function canDownloadHappeningParticipants(happening: BareHappening, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    if (isEditor(user)) {
        return userIsLinked(happening, user);
    }

    return false;
}

export function canCloneHappening(user?: Profile): boolean {
    if (isChiefEditor(user) || isEditor(user) || isPartner(user)) {
        return true;
    }

    return false;
}

export function canCreateSpecificMeasurement(indicator: IndicatorListOutput, user?: Profile): boolean {
    return canCreateMeasurement(user) && indicator.status === IndicatorStatus.Open;
}

export function canEditSpecificMeasurement(
    measurement: MeasurementListOutput | MeasurementOutput,
    user?: Profile,
): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    return (
        canEditMeasurement(user) &&
        isLinkedToMeasurement(measurement, user) &&
        isEditibleMeasurementState(measurement.status)
    );
}

export function isLinkedToMeasurement(measurement: MeasurementListOutput | MeasurementOutput, user?: Profile): boolean {
    if (isChiefEditor(user)) {
        return true;
    }

    return measurement.measurer?.id === user?.id;
}
